import React, { useEffect, useState } from "react";

const PrivacyPolicy = () => {
  const [page, setPage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      {/* <div className="container mx-auto my-5" style={{ paddingTop: "80px" }}>
        <div className="relative rounded-lg flex flex-col md:flex-row items-center md:shadow-xl h-56">
          {" "}
          <div className="z-0 order-2 md:order-2 relative w-full md:w-2/5 h-56 md:h-full overflow-hidden rounded-lg md:rounded-none md:rounded-r-lg">
            {" "}
            <div
              className="absolute inset-0 w-full h-full object-fill object-center bg-blue-400 bg-opacity-30 bg-cover bg-bottom"
              style={{
                backgroundImage:
                  'url("https://images.unsplash.com/photo-1525302220185-c387a117886e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80")',
                backgroundBlendMode: "multiply",
              }}
            ></div>
            <div className="md:hidden absolute inset-0 h-full p-6 pb-6 flex flex-col-reverse justify-start items-start bg-gradient-to-b from-transparent via-transparent to-gray-900">
              <h3 className="w-full font-bold text-2xl text-white leading-tight mb-2">
                Privacy Policy
              </h3>
            </div>
            <svg
              className="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-white -ml-12"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
          </div>
          <div className="bg-white z-10 order-1 md:order-1 w-full h-full md:w-3/5 flex items-center -mt-6 md:mt-0">
            <div className="p-8 md:pr-18 md:pl-14 md:py-12 mx-2 md:mx-0 h-full bg-white rounded-lg md:rounded-none md:rounded-l-lg shadow-xl md:shadow-none">
              <h4 className="hidden md:block text-xl text-gray-400">
                3g Content
              </h4>
              <h3 className="hidden md:block font-bold text-2xl text-gray-700">
                Privacy Policy
              </h3>

              <a
                className="flex items-baseline mt-3 text-blue-600 hover:text-blue-900 focus:text-blue-900"
                href="#"
              ></a>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container bg-white text-black mx-auto my-10 p-5">
        <h1 className="text-3xl font-bold mb-5">Privacy Policy</h1>

        <div className="mb-1">
          <h2 className="text-2xl font-semibold mb-3">1. Introduction</h2>
          <p>
            At Sarvacharya Education Private Ltd, we respect your privacy and
            are committed to protecting your personal data. This Privacy Policy
            outlines how we collect, use, disclose, and safeguard your
            information when you use our website, products, and services. By
            using our services, you consent to the data practices described in
            this policy.
          </p>
        </div>

        <div className="mb-1">
          <h2 className="text-2xl font-semibold mb-3">
            2. Information We Collect
          </h2>
          <p>
            <strong>Personal Information:</strong> We may collect personal
            information that you provide to us, such as your name, email
            address, phone number, billing information, and other similar data.
          </p>
          <p>
            <strong>Usage Data:</strong> We may collect information about how
            you access and use our services. This may include your IP address,
            browser type, operating system, referral URLs, pages visited, and
            the dates and times of your visits.
          </p>
          <p>
            <strong>Cookies and Tracking Technologies:</strong> We use cookies
            and similar tracking technologies to track the activity on our
            services and hold certain information. You can instruct your browser
            to refuse all cookies or to indicate when a cookie is being sent.
          </p>
        </div>

        <div className="mb-1">
          <h2 className="text-2xl font-semibold mb-3">
            3. How We Use Your Information
          </h2>
          <p>We use the information we collect in the following ways:</p>
          <ul className="list-disc pl-5">
            <li>
              <strong>To Provide and Maintain Our Services:</strong> Including
              processing transactions, managing subscriptions, and providing
              customer support.
            </li>
            <li>
              <strong>To Improve Our Services:</strong> By analyzing usage data
              to understand how our services are used and to make improvements.
            </li>
            <li>
              <strong>To Communicate With You:</strong> Including sending you
              updates, newsletters, marketing materials, and other information
              that may be of interest to you.
            </li>
            <li>
              <strong>To Enforce Our Terms and Policies:</strong> And to protect
              the rights, privacy, safety, or property of our company and our
              users.
            </li>
          </ul>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            4. Sharing Your Information
          </h2>
          <p>
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties without your consent, except as
            described in this Privacy Policy:
          </p>
          <ul className="list-disc pl-5">
            <li>
              <strong>Service Providers:</strong> We may share your information
              with third-party service providers who perform services on our
              behalf, such as payment processing, data analysis, email delivery,
              and hosting services.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a merger,
              acquisition, or sale of all or a portion of our assets, your
              personal information may be transferred to the new owner.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We may disclose your
              information if required to do so by law or in response to valid
              requests by public authorities.
            </li>
          </ul>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">5. Data Security</h2>
          <p>
            We implement a variety of security measures to maintain the safety
            of your personal information. However, no method of transmission
            over the Internet, or method of electronic storage, is 100% secure.
            While we strive to use commercially acceptable means to protect your
            personal information, we cannot guarantee its absolute security.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            6. Your Rights and Choices
          </h2>
          <p>
            Depending on your location, you may have the following rights
            regarding your personal information:
          </p>
          <ul className="list-disc pl-5">
            <li>
              <strong>Access and Correction:</strong> You may access and update
              your personal information by logging into your account or
              contacting us directly.
            </li>
            <li>
              <strong>Deletion:</strong> You may request that we delete your
              personal information, subject to certain exceptions.
            </li>
            <li>
              <strong>Opt-Out:</strong> You may opt-out of receiving marketing
              communications from us by following the unsubscribe link in the
              email or contacting us directly.
            </li>
          </ul>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">7. Children's Privacy</h2>
          <p>
            Our services are not intended for individuals under the age of 13.
            We do not knowingly collect personal information from children under
            13. If we become aware that a child under 13 has provided us with
            personal information, we will take steps to delete such information.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            8. Changes to This Privacy Policy
          </h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
