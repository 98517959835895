import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/logo/logo.png";
// Assuming you are using Reactstrap or similar library

export const Footer = () => {
  const footerNavs = [
    { href: "/about", name: "About" },
    { href: "/productsPage", name: "Product" },
    { href: "/contact", name: "Contact" },
    { href: "/privacypolicy", name: "Privacy Policy" },
    { href: "/fundpolicy", name: "Refund Policy" },
    { href: "/returnpolicy", name: "Return Policy" },
    { href: "/shippingpolicy", name: "Shipping Policy" },
    { href: "/terandcondition", name: "Terms And Condition" },
  ];

  return (
    <footer className="footer bg-gray-900 text-white">
      <div className="container mx-auto py-8 px-5">
        <div className="max-w-lg mx-auto text-center">
          <img src={logo} className="w-32 mx-auto mb-4" alt="Logo" />
          <h2 className="text-lg font-bold tracking-tight leading-relaxed">
            C/2A Prashant Complex Chitralaya BARC Station Road Boisar West
            Palghar 401504
          </h2>
        </div>
        <ul className="flex flex-col sm:flex-row flex-wrap justify-center sm:justify-center mt-8 space-y-5 sm:space-y-0 sm:space-x-4">
          {footerNavs.map((item) => (
            <li key={item.name} className="hover:text-gray-400">
              <Link to={item.href}>{item.name}</Link>
            </li>
          ))}
        </ul>
        <div className="mt-8 flex flex-col-reverse sm:flex-row items-center justify-between text-center sm:text-left">
          <div className="mt-4 sm:mt-0">
            &copy; 2024 3g Contents. All rights reserved.
          </div>
          <div className="flex space-x-4 mb-4 sm:mb-0">
            <a href="https://facebook.com" aria-label="Facebook">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                className="h-8 text-blue-600"
              >
                <path
                  fill="currentColor"
                  d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
            </a>
            <a href="https://twitter.com" aria-label="Twitter">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="h-8 text-blue-400"
              >
                <path
                  fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                ></path>
              </svg>
            </a>
            <a href="https://instagram.com" aria-label="Instagram">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="h-8 text-pink-500"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-115.1 51.5-115.1 115.1S160.5 371.1 224.1 371.1 339.1 319.6 339.1 256 287.6 141 224.1 141zm0 190.4c-41.7 0-75.1-33.4-75.1-75.1s33.4-75.1 75.1-75.1 75.1 33.4 75.1 75.1-33.4 75.1-75.1 75.1zm146.4-194.3c0 14.9-12.1 27-27 27-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27zm76.1 27.2c-1.7-35.7-9.9-67.3-36.2-93.6S366.7 7.7 331 6c-35.7-1.7-142.8-1.7-178.5 0-35.7 1.7-67.3 9.9-93.6 36.2S7.7 145.3 6 181c-1.7 35.7-1.7 142.8 0 178.5 1.7 35.7 9.9 67.3 36.2 93.6s57.9 34.5 93.6 36.2c35.7 1.7 142.8 1.7 178.5 0 35.7-1.7 67.3-9.9 93.6-36.2s34.5-57.9 36.2-93.6c1.7-35.7 1.7-142.8 0-178.5zm-48.1 214c-7.8 19.7-22.9 35.8-42.6 42.6-29.5 11.7-99.6 9-132.4 9s-102.9 2.6-132.4-9c-19.7-7.8-35.8-22.9-42.6-42.6-11.7-29.5-9-99.6-9-132.4s-2.6-102.9 9-132.4c7.8-19.7 22.9-35.8 42.6-42.6 29.5-11.7 99.6-9 132.4-9s102.9-2.6 132.4 9c19.7 7.8 35.8 22.9 42.6 42.6 11.7 29.5 9 99.6 9 132.4s2.7 102.9-9 132.4z"
                ></path>
              </svg>
            </a>
            <a href="https://linkedin.com" aria-label="LinkedIn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="h-8 text-blue-700"
              >
                <path
                  fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zm-46.14-339C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zm393.88 339h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
