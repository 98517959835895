import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/product/student_id.jpg";
import projImg2 from "../assets/product/board_img.png";
import projImg3 from "../assets/product/lsc_screen.jpg";
import projImg4 from "../assets/product/projector.jpg";
import projImg5 from "../assets/product/laptop.jpg";
import projImg6 from "../assets/product/answer_sheet.jpg";
import projImg7 from "../assets/product/card_holder.jpg";
import projImg8 from "../assets/product/bio_matric.jpg";
import projImg9 from "../assets/product/erp_panel.png";

import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useNavigate } from "react-router-dom";

export const Projects = () => {
  const navigate = useNavigate();
  const projects = [
    {
      title: "Students ID",
      description: "Chip Card",
      imgUrl: projImg1,
    },
    {
      title: "Interactive Board",
      description: "Altop and Many more",
      imgUrl: projImg2,
    },
    {
      title: "Brands",
      description: "LG, MaxHub, Senses, Samsung etc",
      imgUrl: projImg3,
    },
    {
      title: "Range of Projector",
      description: "Refurbished and New",
      imgUrl: projImg4,
    },
    {
      title: "Laptops",
      description: "Various Range of Laptops",
      imgUrl: projImg5,
    },
    {
      title: "Customize Answer Sheet",
      description: "Various Types of Answer Sheet",
      imgUrl: projImg6,
    },
    {
      title: "Card Holder",
      description: "Varities of Card Holders",
      imgUrl: projImg7,
    },
    {
      title: "Bio Metric",
      description: "Whats app Updates",
      imgUrl: projImg8,
    },
    {
      title: "ERP Panel",
      description: "Education Software",
      imgUrl: projImg9,
    },
  ];

  const handleCardClick = (project) => {
    // Pass data to productsPage if needed, e.g., via state or URL params
    navigate("/productsPage");
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Product</h2>
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav> */}
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                onClick={() => handleCardClick(project)}
                              />
                            );
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
