import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState("");
  const [errors, setErrors] = useState({});
  const [cities, setCities] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address1: "",
    address2: "",
    landmark: "",

    state: "",
    city: "",
    pincode: "",
  });

  // Retrieve stored data
  const title = sessionStorage.getItem("title");
  const productImage = sessionStorage.getItem("productImage");
  const productPrice = sessionStorage.getItem("productPrice");
  const productDescription = sessionStorage.getItem("productDescription");

  // If there's no data, redirect back to the products page
  useEffect(() => {
    if (!productImage || !productPrice) {
      navigate("/productsPage"); // Redirect if no data found
    }
  }, [productImage, productPrice, navigate]);

  const handleBackClick = () => {
    navigate(-1); // This navigates to the previous page in history
  };

  const handleContinueClick = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required";
    else if (!/^[a-zA-Z\s]+$/.test(formData.name))
      newErrors.name = "Name should contain only letters and spaces";

    if (!formData.email) newErrors.email = "Email is required";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email))
      newErrors.email = "Invalid email address";

    if (!formData.phone) newErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(formData.phone))
      newErrors.phone = "Phone number must be 10 digits";

    if (!formData.address1) newErrors.address1 = "Address Line 1 is required";

    if (!formData.state) newErrors.state = "State is required";

    if (!formData.city) newErrors.city = "City is required";

    if (!formData.pincode) newErrors.pincode = "Pincode is required";
    else if (!/^\d{6}$/.test(formData.pincode))
      newErrors.pincode = "Pincode must be 6 digits";

    // If there are errors, set them in the state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log(formData);
      // If there are no errors, proceed to the next page
      sessionStorage.setItem("checkoutData", JSON.stringify(formData));
      navigate("/summaryPage", {
        state: {
          title,
          productImage,
          productPrice,
          productDescription,
          formData,
        },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "", // Clear the error message for the field as the user types
    }));
  };

  // useEffect(() => {
  //   // Cleanup session data when leaving the checkout page
  //   return () => {
  //     sessionStorage.removeItem("title");
  //     sessionStorage.removeItem("productImage");
  //     sessionStorage.removeItem("productPrice");
  //     sessionStorage.removeItem("productDescription");
  //   };
  // }, []);

  return (
    <>
      <Container className="pt-20 mb-5 mx-auto max-w-md px-6 lg:px-12">
        <div className="bg-indigo-50 space-y-8 p-8 rounded-lg shadow-lg">
          <div class="flex gap-3 bg-white border border-gray-300 rounded-xl overflow-hidden items-center justify-start">
            <div class="relative w-32 h-32 flex-shrink-0">
              <img
                class="ml-2 absolute left-0 top-0 w-full h-full object-cover object-center transition duration-50"
                loading="lazy"
                src={productImage}
              />
            </div>

            <div class="flex flex-col mt-2 mb-2 gap-2 py-2">
              <p class="text-xl text-black font-bold">{title}</p>

              <p class="text-gray-500 -mt-4">{productDescription}</p>

              <span class="flex items-center -mt-4 justify-start text-gray-500">
                ₹ {productPrice} /-
              </span>
            </div>
          </div>
          <div className="mt-8 p-4 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md">
            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
              <div className="text-yellow-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 sm:w-5 h-6 sm:h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div className="text-sm text-gray-900 font-medium ml-3">
                Checkout
              </div>
            </div>
            <div className="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">
              Complete your shipping and payment details below.
            </div>
          </div>

          {/* <div className="mt-10 bg-white shadow-lg rounded-lg overflow-hidden px-6 py-4 flex items-center space-x-4">
            <h3 className="text-lg font-semibold text-gray-700">
              Select Quantity
            </h3>
            <select
              className="shadow appearance-none border rounded w-1/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="quantity"
              name="quantity"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div> */}

          <div className="mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
            <form className="py-4 px-6">
              {/* Name Field */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                )}
              </div>

              {/* Email Field */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                )}
              </div>

              {/* Phone Number Field */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="phone"
                >
                  Phone Number
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="tel"
                  placeholder="Enter your phone number"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && (
                  <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
                )}
              </div>

              {/* Address Fields */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="address1"
                >
                  Address Line 1
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="address1"
                  type="text"
                  placeholder="Street address, PO Box, etc."
                  value={formData.address1}
                  onChange={handleChange}
                />
                {errors.address1 && (
                  <p className="text-red-500 text-xs mt-1">{errors.address1}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="address2"
                >
                  Address Line 2
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="address2"
                  type="text"
                  placeholder="Apartment, suite, unit, building, floor, etc."
                  value={formData.address2}
                  onChange={handleChange}
                />
                {errors.address2 && (
                  <p className="text-red-500 text-xs mt-1">{errors.address2}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="landmark"
                >
                  Landmark
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="landmark"
                  type="text"
                  placeholder="Landmark (optional)"
                  value={formData.landmark}
                  onChange={handleChange}
                />
                {errors.landmark && (
                  <p className="text-red-500 text-xs mt-1">{errors.landmark}</p>
                )}
              </div>

              {/* State and Pincode */}
              <div className="mb-4 grid grid-cols-2 gap-4">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 font-bold mb-2"
                    htmlFor="state"
                  >
                    State
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="state"
                    value={formData.state}
                    onChange={handleChange}
                  >
                    <option value="">Select your state</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                  {errors.state && (
                    <p className="text-red-500 text-xs mt-1">{errors.state}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 font-bold mb-2"
                    htmlFor="city"
                  >
                    City
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="city"
                    type="text"
                    placeholder="Enter your city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                  {errors.city && (
                    <p className="text-red-500 text-xs mt-1">{errors.city}</p>
                  )}
                </div>
                <div>
                  <label
                    className="block text-gray-700 font-bold mb-2"
                    htmlFor="pincode"
                  >
                    Pincode
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="pincode"
                    type="text"
                    placeholder="Enter your pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                  />
                  {errors.pincode && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.pincode}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="flex justify-between space-x-4">
            <button
              onClick={handleBackClick}
              className="submit-button px-4 py-3 rounded-full bg-gray-400 text-white focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
            >
              Back
            </button>
            <button
              onClick={handleContinueClick}
              className="submit-button px-4 py-3 rounded-full bg-pink-400 text-white focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
            >
              Continue
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Checkout;
