import { useNavigate } from "react-router-dom";
import content from "../../../../assets/EcontentB2B/econtent.png";

const EcontentCard = ({ product }) => {
  const { course, application, subjects, content_type, assessment, price } =
    product;
  const navigate = useNavigate();

  const handlePurchaseClick = () => {
    // Store the image name and price in sessionStorage
    sessionStorage.setItem(
      "productImage",
      "https://i.ibb.co/tJyFyjy/b2b-e-COMMERCE-WEBSITE.png"
    ); // Image file name
    sessionStorage.setItem("productPrice", price); // Product price
    sessionStorage.setItem("productDescription", subjects);
    sessionStorage.setItem("title", course);

    // Navigate to the checkout page
    navigate("/checkout");
  };

  return (
    <div className="bg-white  border-t-8 border-green-500  mb-6 rounded-lg shadow-md p-4 md:p-6 overflow-hidden transition-transform transform hover:scale-105">
      {/* Image Section */}
      <div className="mb-4">
        <img
          src={content} // Replace with actual image source
          alt={course}
          className="w-full h-48 object-cover rounded-t-lg"
        />
      </div>
      <div className="mb-2">
        <div className="text-slate-900 dark:text-slate-200 font-semibold mb-1">
          {course}
        </div>
        <div className="inline-flex items-baseline mb-2">
          <span className="text-slate-900 dark:text-slate-200 font-bold text-xl">
            ₹
          </span>
          <span className="text-slate-900 dark:text-slate-200 font-bold text-xl">
            {price}
          </span>
        </div>
        <a
          className="w-full cursor-pointer inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
          onClick={handlePurchaseClick}
        >
          Purchase
        </a>
      </div>

      <div className="overflow-hidden">
        <table className="divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th className="px-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Details
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-1 whitespace-normal text-sm font-medium text-gray-900">
                Application
              </td>
              <td className="px-1 whitespace-normal text-sm text-gray-500 break-words">
                {application}
              </td>
            </tr>
            <tr>
              <td className="px-1 whitespace-normal text-sm font-medium text-gray-900">
                Subjects
              </td>
              <td className="px-1 whitespace-normal text-sm text-gray-500 break-words">
                {subjects.join(", ")}
              </td>
            </tr>
            <tr>
              <td className="px-1 whitespace-normal text-sm font-medium text-gray-900">
                Content Type
              </td>
              <td className="px-1 whitespace-normal text-sm text-gray-500 break-words">
                {content_type}
              </td>
            </tr>
            <tr>
              <td className="px-1 whitespace-normal text-sm font-medium text-gray-900">
                Assessment
              </td>
              <td className="px-1 whitespace-normal text-sm text-gray-500 break-words">
                {assessment.join(", ")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EcontentCard;
