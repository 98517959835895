import EcontentCard from "./EcontentCard";
import "./content.css";

const Econtent = () => {
  // Example usage with the JSON data
  const productData = [
    {
      course: "MHSB 1st to 12th std",
      application: "App Based",
      subjects: ["Languages", "Maths", "Science", "Social Science"],
      content_type: "Audio n Video",
      assessment: ["Online Quiz", "Online Test"],
      price: 15000,
    },
    {
      course: "CBSE 1st to 12th std",
      application: "App Based",
      subjects: ["Languages", "Maths", "Science", "Social Science"],
      content_type: "Audio n Video",
      assessment: ["Online Quiz", "Online Test"],
      price: 18000,
    },
    {
      course: "ICSE 1st to 12th std",
      application: "App Based",
      subjects: ["Languages", "Maths", "Science", "Social Science"],
      content_type: "Audio n Video",
      assessment: ["Online Quiz", "Online Test"],
      price: 21000,
    },
  ];

  return (
    <>
      <div class="flex w-full coverAll  p-4 rounded-lg">
        <div class="w-1/3">
          <iframe
            class="w-full rounded-lg"
            src="https://www.youtube.com/embed/tCGZUSiRXPk?autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div class="w-1/3"></div>
      </div>

      <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {productData.map((product) => (
          <EcontentCard key={product.course} product={product} />
        ))}
      </div>
    </>
  );
};

export default Econtent;
