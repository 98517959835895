import React from "react";
import ProductCard from "./BrandPanel/ProductCard";
import "../../../Style/PanelLed.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import video from "../../../assets/videoFile/smartTv.mp4";

// Main BrandLedPanel component
const BrandLedPanel = () => {
  //   const products = [
  //     {
  //       title: "Platinum Led Panel 55'",
  //       price: "29,000",
  //       product: {
  //         display: {
  //           resolution: "4K UHD (3840x2160)",
  //           brands: ["LG", "BOE", "AUOoriginalOC", "CSOT"],
  //           brightness: ["400 cd/m2", "450 cd/m2", "500 cd/m2"],
  //           response_time: ["4.0 ms", "5.0 ms"],
  //         },
  //         software: {
  //           android_version: ["11.0", "13.0"],
  //           processor: {
  //             model: "T982 Latest Version",
  //             architecture: "Quad-core A55",
  //             gpu: ["Mali G51 MP2", "Mali G53 MP2"],
  //           },
  //           memory: {
  //             ram: ["4 GB", "8 GB"],
  //             storage: ["32 GB", "128 GB"],
  //           },
  //           connectivity: {
  //             wifi: "2.4/5.0 GHz",
  //             bluetooth: "Yes",
  //           },
  //         },
  //         ops: {
  //           slot: "80 PIN OPS (optional)",
  //           license_window: ["win 10 pro", "win 11 pro", "Latest"],
  //           processor_options: ["i3", "i5", "i7", "i9"],
  //           storage_options: {
  //             ssd: ["128GB", "256GB", "512GB"],
  //             hdd: ["500GB", "1TB", "More"],
  //           },
  //         },
  //         touch_technology: {
  //           zero_bonding: "Yes",
  //           type: "IR",
  //           points: ["10 points", "20 points"],
  //         },
  //         audio_video: {
  //           speakers: ["15W*2", "20W*2"],
  //           microphone_camera: "Optional",
  //         },
  //         ports: {
  //           standard_ports: [
  //             "USBx4",
  //             "RJ45",
  //             "HDMIx2",
  //             "RS232x1",
  //             "TouchUSBx1",
  //             "Mic/Earphonex1",
  //             "SPDIFx1",
  //           ],
  //           optional_ports: ["HDMI (OUT)", "VGAx1"],
  //         },
  //         power: {
  //           consumption: ["150W", "200W"],
  //         },
  //         lifespan: "60,000 hours",
  //       },
  //     },
  //     {
  //       title: "Diamond Led Panel 65'",
  //       price: "45,000",
  //       product: {
  //         display: {
  //           resolution: "4K UHD (3840x2160)",
  //           brands: ["LG", "BOE", "AUOoriginalOC", "CSOT"],
  //           brightness: ["400 cd/m2", "450 cd/m2", "500 cd/m2"],
  //           response_time: ["4.0 ms", "5.0 ms"],
  //         },
  //         software: {
  //           android_version: ["11.0", "13.0"],
  //           processor: {
  //             model: "T982 Latest Version",
  //             architecture: "Quad-core A55",
  //             gpu: ["Mali G51 MP2", "Mali G53 MP2"],
  //           },
  //           memory: {
  //             ram: ["4 GB", "8 GB"],
  //             storage: ["32 GB", "128 GB"],
  //           },
  //           connectivity: {
  //             wifi: "2.4/5.0 GHz",
  //             bluetooth: "Yes",
  //           },
  //         },
  //         ops: {
  //           slot: "80 PIN OPS (optional)",
  //           license_window: ["win 10 pro", "win 11 pro", "Latest"],
  //           processor_options: ["i3", "i5", "i7", "i9"],
  //           storage_options: {
  //             ssd: ["128GB", "256GB", "512GB"],
  //             hdd: ["500GB", "1TB", "More"],
  //           },
  //         },
  //         touch_technology: {
  //           zero_bonding: "Yes",
  //           type: "IR",
  //           points: ["10 points", "20 points"],
  //         },
  //         audio_video: {
  //           speakers: ["15W*2", "20W*2"],
  //           microphone_camera: "Optional",
  //         },
  //         ports: {
  //           standard_ports: [
  //             "USBx4",
  //             "RJ45",
  //             "HDMIx2",
  //             "RS232x1",
  //             "TouchUSBx1",
  //             "Mic/Earphonex1",
  //             "SPDIFx1",
  //           ],
  //           optional_ports: ["HDMI (OUT)", "VGAx1"],
  //         },
  //         power: {
  //           consumption: ["150W", "200W"],
  //         },
  //         lifespan: "60,000 hours",
  //       },
  //     },
  //     {
  //       title: "Diamond Led Panel 85'",
  //       price: "90,000",
  //       product: {
  //         display: {
  //           resolution: "4K UHD (3840x2160)",
  //           brands: ["LG", "BOE", "AUOoriginalOC", "CSOT"],
  //           brightness: ["400 cd/m2", "450 cd/m2", "500 cd/m2"],
  //           response_time: ["4.0 ms", "5.0 ms"],
  //         },
  //         software: {
  //           android_version: ["11.0", "13.0"],
  //           processor: {
  //             model: "T982 Latest Version",
  //             architecture: "Quad-core A55",
  //             gpu: ["Mali G51 MP2", "Mali G53 MP2"],
  //           },
  //           memory: {
  //             ram: ["4 GB", "8 GB"],
  //             storage: ["32 GB", "128 GB"],
  //           },
  //           connectivity: {
  //             wifi: "2.4/5.0 GHz",
  //             bluetooth: "Yes",
  //           },
  //         },
  //         ops: {
  //           slot: "80 PIN OPS (optional)",
  //           license_window: ["win 10 pro", "win 11 pro", "Latest"],
  //           processor_options: ["i3", "i5", "i7", "i9"],
  //           storage_options: {
  //             ssd: ["128GB", "256GB", "512GB"],
  //             hdd: ["500GB", "1TB", "More"],
  //           },
  //         },
  //         touch_technology: {
  //           zero_bonding: "Yes",
  //           type: "IR",
  //           points: ["10 points", "20 points"],
  //         },
  //         audio_video: {
  //           speakers: ["15W*2", "20W*2"],
  //           microphone_camera: "Optional",
  //         },
  //         ports: {
  //           standard_ports: [
  //             "USBx4",
  //             "RJ45",
  //             "HDMIx2",
  //             "RS232x1",
  //             "TouchUSBx1",
  //             "Mic/Earphonex1",
  //             "SPDIFx1",
  //           ],
  //           optional_ports: ["HDMI (OUT)", "VGAx1"],
  //         },
  //         power: {
  //           consumption: ["150W", "200W"],
  //         },
  //         lifespan: "60,000 hours",
  //       },
  //     },
  //   ];

  const productData = [
    {
      title: 'EyeRIS A10 65" AI',
      Price: "87000",
      Price2: "95000",
      details: {
        Finish: "Pearl White",
        Display:
          "Ultra HD IPS LCD A+ Display 3840 * 2160 (pixels) with 60Hz refresh rate",
        Surface:
          "Matte finish surface with 3.2 mm Toughened Glass MoHS Level 7 Hardness",
        "Touch Technology": "Advance EyeRIS A10 edge Optical Touch",
        "Touch Points":
          "40 Simultaneous touch points (Windows), 20 Simultaneous touch points (Android)",
        OS: "Android 12 Upgradable to Android 14",

        Storage:
          "Storage Unlimited cloud storage* bundled with 32 GB / 128 GB* Onboard",
      },
    },
    {
      title: 'EyeRIS A10 75" AI',
      Price: "100000",
      Price2: "106000",
      details: {
        Finish: "Pearl White",
        Display:
          "Ultra HD IPS LCD A+ Display 3840 * 2160 (pixels) with 60Hz refresh rate",
        Surface:
          "Matte finish surface with 3.2 mm Toughened Glass MoHS Level 7 Hardness",
        "Touch Technology": "Advance EyeRIS A10 edge Optical Touch",
        "Touch Points":
          "40 Simultaneous touch points (Windows), 20 Simultaneous touch points (Android)",
        OS: "Android 12 Upgradable to Android 14",

        Storage:
          "Storage Unlimited cloud storage* bundled with 32 GB / 128 GB* Onboard",
      },
    },
    {
      title: 'EyeRIS A10 86" AI',
      Price: "155000 ",
      Price2: "162000",
      details: {
        Display:
          "Ultra HD IPS LCD A+ Display 3840 * 2160 (pixels) with 60Hz refresh rate",
        Surface:
          "Matte finish surface with 3.2 mm Toughened Glass MoHS Level 7 Hardness",
        "Touch Technology": "Advance EyeRIS A10 edge Optical Touch",
        "Touch Points":
          "40 Simultaneous touch points (Windows), 20 Simultaneous touch points (Android)",
        OS: "Android 12 Upgradable to Android 14",

        Storage:
          "Storage Unlimited cloud storage* bundled with 32 GB / 128 GB* Onboard",
      },
    },
  ];

  const settings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop sliding
    speed: 500, // Slide transition speed
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* <div className="bg-gray-100 banner dark:bg-gray-800 py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
              Enjoying my content?
            </h2>
            <p className="mt-3 text-xl text-gray-500 dark:text-gray-400 sm:mt-4">
              Consider buying me a coffee to support my work!
            </p>
          </div>
        </div>
      </div> */}

      <div class="flex w-full bg-purple-800 p-4 rounded-lg">
        <div class="flex flex-col w-2/3 pr-4 bg">
          <p class="text-2xl font-black mb-2 text-gray-50">Smart Panel</p>
          <p class="text-lg font-light leading-5 text-gray-300">
            Where the earth is stepped on, there the sky is upheld
          </p>
          <div class="flex h-full items-end text-gray-300 hover:text-gray-50">
            <button class="text-sm font-semibold flex items-center space-x-2">
              <span>BOOK NOW</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="w-1/3">
          <iframe
            class="w-full rounded-lg"
            src="https://www.youtube.com/embed/tHPPYFZcyn8?autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div className="bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {productData.map((product) => (
              <ProductCard
                key={product.title}
                product={product}
                price={product.Price}
                price2={product.Price2}
                disc={product.details.Display}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandLedPanel;
