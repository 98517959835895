import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/chanacky.png";
import slider2 from "../assets/slider/Slider2.png";
import slider3 from "../assets/slider/Slider3.png";

import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import TrackVisibility from "react-on-screen";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Guru", "Supporter", "Helper"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  const settings = {
    dots: true, // Show dots navigation
    infinite: true,
    speed: 9000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide next/previous arrows
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12}>
            <Slider {...settings}>
              <div>
                <Row className="align-items-center">
                  <Col xs={12} md={6} xl={7}>
                    <div className="slide-content">
                      <span className="tagline">Welcome to 3g Content</span>
                      <h2>
                        Empower Every Learner: Personalized Pathways to Success
                      </h2>
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={5}>
                    <img
                      src={headerImg} // Replace with your specific image for this slide
                      alt="Slide 1"
                      className="img-fluid"
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="align-items-center">
                  <Col xs={12} md={6} xl={7}>
                    <div className="slide-content">
                      <span className="tagline">Welcome to 3g Content</span>
                      <h2>
                        Discover, Engage, Achieve: Learning that Inspires!
                      </h2>
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={5}>
                    <img
                      src={slider2} // Replace with your specific image for this slide
                      alt="Slide 2"
                      className="img-fluid"
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="align-items-center">
                  <Col xs={12} md={6} xl={7}>
                    <div className="slide-content">
                      <span className="tagline">Welcome to 3g Content</span>
                      <h2>
                        3G: Revolutionizing Connectivity with Speed, Mobility,
                        and Innovation.
                      </h2>
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={5}>
                    <img
                      src={slider3} // Replace with your specific image for this slide
                      alt="Slide 3"
                      className="img-fluid"
                    />
                  </Col>
                </Row>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
