import React, { useEffect, useState } from "react";

const ReturnPolicy = () => {
  const [page, setPage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <div className="container bg-white text-black mx-auto my-10 p-5">
        <h1 className="text-3xl font-bold mb-5">Return Policy</h1>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            1. Return/Exchange Period
          </h2>
          <p>
            We offer return/exchange within the first 3 days from the date of
            your purchase. If 3 days have passed since your purchase, you will
            not be offered a return, exchange, or refund of any kind.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            2. Eligibility for Return/Exchange
          </h2>
          <p>To be eligible for a return or an exchange:</p>
          <ul className="list-disc pl-5">
            <li>
              The purchased item should be unused and in the same condition as
              you received it.
            </li>
            <li>The item must have original packaging.</li>
            <li>
              Items purchased on sale may not be eligible for a return/exchange.
            </li>
            <li>
              Only items found defective or damaged are replaced based on an
              exchange request.
            </li>
          </ul>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            3. Non-Returnable Items
          </h2>
          <p>
            Certain categories of products/items are exempted from returns or
            refunds. These categories will be identified at the time of
            purchase.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            4. Processing Returns/Exchanges
          </h2>
          <p>
            For accepted return/exchange requests, once your returned
            product/item is received and inspected, we will send you an email to
            notify you about receipt. If approved after our quality check, your
            request will be processed in accordance with our policies.
          </p>
        </div>
      </div>
    </>
  );
};

export default ReturnPolicy;
