import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, onClick }) => {
  return (
    <Col sm={6} md={4}>
      <div className="proj-imgbx" onClick={onClick}>
        <img
          src={imgUrl}
          alt={title}
          className="img-fluid rounded-lg"
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  );
};
