import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { motion } from "framer-motion";
import img from "../../assets/avtar/avatar-man.png";

import { styles } from "../../Style/style";

import { fadeIn, textVariant } from "../../utils/motion";

const testimonials = [
  {
    id: 1,
    quote: "The best experience ever",
    text: "Sharma's Classes (Bhayander West)",
    image: img,
    name: "Sharma's Classes",
    title: "Bhayander West",
  },
  {
    id: 2,
    quote: "The best experience ever",
    text: "Efficiency, punctuality and honesty are hallmarks of their service.",
    image: img,
    name: "Sameer Sharma",
    title: "Owner",
  },
  {
    id: 3,
    quote: "The best experience ever",
    text: "It has been a great experience with 3G content. This software has helped our classes and students a lot as we can set our Question paper with great ease and they have  supported and guided us a lot as I'm unaware of technology but can say it's a user friendly software.",
    image: img,
    name: "Sonal D'cruz",
    title: " Angel Coaching Classes (Virar)",
  },

  {
    id: 4,
    title: "Mahesh Tutorials (Dombivali East)",
    text: "Already Created more than 200 question Papers",
    image: img,
    name: "Jitesh Limkar Sir",
  },
  {
    id: 5,
    title: "3G Classes (Bhayander East)",
    text: "Already Created 200 plus question papers",
    image: img,
    name: "Pooja Yadav Madam",
  },
  {
    id: 6,
    title: "Noble Classes (Kandivali West)",
    text: "Created 70 plus question papers",
    image: img,
    name: "Sejal Tripathi Madam",
  },
  {
    id: 7,
    title: "Excellent Classes (Virar East)",
    text: "Created 80 plus question papers",
    image: img,
    name: "Umesh Rajak Sir",
  },
  {
    id: 8,
    title: "Ved Classes (Virar West)",
    text: "Created more than 100 papers",
    image: img,
    name: "Rakesh Sir",
  },

  // Add more testimonials here
];

const Testimonal = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Slow down the slide transition
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true,
    autoplaySpeed: 4000, // Slow down the autoplay speed
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 slides on medium screens
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Show 2 slides on small screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Show 1 slide on extra small screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    // <div className="max-w-6xl mx-auto">
    //   <div className="text-center pt-5">
    //     <h2>Testimonial</h2>
    //     <h2>Here's what our customers said</h2>
    //     <p>
    //       Testimonials is a great way to increase brand trust and awareness. Use
    //       this section to highlight your popular customers.
    //     </p>
    //   </div>

    // </div>

    <div className={`mt-12 bg-black rounded-[20px]`}>
      <Container>
        <section class="text-gray-600 body-font">
          <div class="container px-5 py-24 mx-auto">
            <motion.div variants={textVariant()}>
              <p className={styles.sectionSubText}>What others say</p>
              <h2 className={styles.sectionHeadText}>Testimonials.</h2>
            </motion.div>
            <Slider {...settings}>
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="p-4">
                  <div className="h-full text-white bg-gradient-to-r from-purple-600 to-pink-600 flex flex-col justify-between  p-8 rounded">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="block w-5 h-5 text-white mb-4"
                      viewBox="0 0 975.036 975.036"
                    >
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                    </svg>
                    <p className="leading-relaxed mb-6">{testimonial.text}</p>
                    <div className="inline-flex items-center mt-auto">
                      <img
                        alt="testimonial"
                        src={testimonial.image}
                        className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                      />
                      <span className="flex-grow text-white flex flex-col pl-4">
                        <span className="title-font font-medium text-white">
                          {testimonial.name}
                        </span>
                        <span className="text-white text-sm">
                          {testimonial.title}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default Testimonal;
