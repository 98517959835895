import React, { Component } from "react";
import { Container } from "react-bootstrap";
class ReserchAndPaper extends Component {
  state = {};
  render() {
    return (
      <Container className="p-5">
        <div className="relative flex p-5 min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
          <div className="w-full items-center mx-auto max-w-screen-xl">
            <div className="group grid w-full grid-cols-2">
              <div className="pl-16 relative flex items-end flex-col before:block before:absolute before:h-1/6 before:w-4 before:bg-blue-500 before:bottom-0 before:left-0 before:rounded-lg  before:transition-all group-hover:before:bg-orange-300 overflow-hidden">
                <div className="absolute top-0 left-0 bg-blue-500 w-4/6 px-12 py-14 flex flex-col justify-center rounded-xl group-hover:bg-sky-600 transition-all ">
                  <span className="block mb-10 font-bold group-hover:text-orange-300">
                    HERE WE ARE
                  </span>
                  <h2 className="text-white font-bold text-3xl">
                    Our Research & Practice Pattern
                  </h2>
                </div>
                <a
                  className="font-bold text-sm flex mt-2 mb-8 items-center gap-2"
                  href=""
                >
                  <span>MORE ABOUT US</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </a>
                <div className="rounded-xl overflow-hidden">
                  <img
                    src="https://i.ibb.co/D8p4Syt/DALL-E-2024-08-19-19-34-02-An-educational-scene-showing-students-collaborating-in-a-classroom-engage.webp"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="pl-12">
                  <p className="peer mb-6 text-gray-400">
                    Emphasis on Textbook Exercises: While solving exercises from
                    textbooks can reinforce understanding of basic concepts and
                    provide practice, relying solely on them may limit students'
                    ability to think critically and apply knowledge in diverse
                    contexts. It's essential to strike a balance between
                    textbook exercises and activities that promote higher-order
                    thinking skills.
                  </p>
                  <p className="mb-6 text-gray-400">
                    Low Representation of HOTS in Board Exams: If only 2% of
                    questions in the 10th and 12th standard Board exams come
                    from high-order thinking skills, it might indicate a
                    misalignment between the curriculum and the skills required
                    for deeper understanding and application of knowledge.
                    Encouraging HOTS in teaching practices could better prepare
                    students for future academic and professional endeavors.
                  </p>
                  <p className="mb-6 text-gray-400">
                    Content Coverage vs. Depth of Understanding: While it's
                    important to cover the prescribed curriculum
                    comprehensively, focusing solely on content coverage may
                    lead to surface-level understanding. Encouraging
                    inquiry-based learning, problem-solving activities, and
                    projects can help students delve deeper into topics and
                    develop critical thinking skills.
                  </p>
                  <p className="mb-6 text-gray-400">
                    Pedagogical Strategies: Incorporating various teaching
                    strategies such as inquiry-based learning, problem-based
                    learning, cooperative learning, and project-based learning
                    can foster higher-order thinking skills among students.
                    These methods encourage active engagement, critical
                    analysis, and application of knowledge in real-world
                    contexts.
                  </p>
                  <p className="mb-6 text-gray-400">
                    Teacher Training and Support: Teachers play a crucial role
                    in fostering higher-order thinking skills among students.
                    Providing professional development opportunities, resources,
                    and support for teachers to implement innovative teaching
                    strategies can enhance the quality of education and promote
                    deeper learning outcome.
                  </p>
                  <p className="mb-6 text-gray-400">
                    Assessment Practices: Assessments should align with learning
                    objectives and promote the application of knowledge rather
                    than rote memorization. Including questions that require
                    critical thinking, problem-solving, and analysis can better
                    reflect students' understanding and readiness for future
                    challenges.
                  </p>
                  <p className="mb-6 text-gray-400">
                    Overall, while covering the textbook content is important,
                    it's equally essential to promote higher-order thinking
                    skills to prepare students for academic success and lifelong
                    learning. Balancing content coverage with opportunities for
                    inquiry, analysis, and application can lead to a more
                    holistic and effective educational experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default ReserchAndPaper;
