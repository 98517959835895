import { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const SummaryPage = () => {
  const location = useLocation();
  const {
    title,
    productImage,
    productPrice = 0,
    productDescription,
    formData,
  } = location.state || {};
  const [quantity, setQuantity] = useState(1);
  const basePrice = parseFloat(productPrice) || 0; // Static price for one unit
  const taxRate = 18.0; // 18% tax
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [applied, setApplied] = useState(false);
  const [error, setError] = useState("");

  const promoCodes = {
    SAVE15: 15,
    OFFER70: 70,
    DEAL45: 45,
    DISCOUNT95: 95,
    COUPON35: 35,
    OFF60: 60,
    SALE10: 10,
    TOP100: 100,
    PROMO25: 25,
    SAVE85: 85,
    OFFER5: 5,
    DEAL55: 55,
    DISCOUNT20: 20,
    COUPON30: 30,
    OFF50: 50,
    SALE80: 80,
    PROMO65: 65,
    SAVE90: 90,
    OFFER75: 75,
    DEAL40: 40,
  };

  // Calculate the subtotal
  const subtotal = parseFloat((basePrice * quantity).toFixed(2));

  // Calculate the taxes
  const taxes = parseFloat(((subtotal * taxRate) / 100).toFixed(2));
  // Dividing by 100 to get the correct percentage
  // Calculate the discount
  const discountAmount = parseFloat(((subtotal * discount) / 100).toFixed(2));

  // Calculate the total amount
  const total = (subtotal + taxes - discountAmount).toFixed(2);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleApplyPromo = () => {
    if (promoCodes[promoCode]) {
      setDiscount(promoCodes[promoCode]);
      setAppliedCoupon(promoCode);
      setApplied(true);
      setPromoCode(""); // Clear the promo code input after applying
    } else {
      alert("Invalid promo code!");
      setDiscount(0);
      setApplied(false);
      setAppliedCoupon("");
    }
  };

  const handleRemovePromo = () => {
    setDiscount(0);
    setApplied(false);
    setAppliedCoupon("");
  };

  const handlePayment = async () => {
    setError(""); // Clear any previous errors
    try {
      const response = await axios.post(
        "https://dev-app.3gcontent.com/api/payment-gateway/make",
        {
          name: formData?.name || "Default Name",
          mobile: formData?.phone || "0000000000",
          email: formData?.email || "default@example.com",
          address_line_1: formData?.addressLine1 || "",
          address_line_2: formData?.addressLine2 || "",
          landmark: formData?.landmark || "",
          city: formData?.city || "",
          state: formData?.state || "",
          pincode: formData?.pincode || "",
          price: subtotal.toFixed(2),
          gst: taxes.toFixed(2),
          amount: total,
          product: title,
          redirect_url: "https://webhook.site/",
          payment_success: "https://www.3gcontent.com/sucesspayment",
          payment_failed: "https://www.3gcontent.com/failedpayment",
        },
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Cookie:
              "XSRF-TOKEN=eyJpdiI6InhJQXowSVFVWnhSZVNDdDE1OXAxbFE9PSIsInZhbHVlIjoiZStXT044NVlSVmxzS282a1BKYVIxa3pwODdKZTR6djRXNks5K0M5OUI1TnU0eVpQSEtUUVNUZWxkWTFjM1VFTTgyVDNYWGxGYjN6UCtZTjk0cDNQeGlHMjViaWRrNGJ6L3BFSlFCeTBsUkRRZDdNNTJja1pOeUxhUXBUTnZ4Z1ciLCJtYWMiOiIzZTk3NDRhYTViYTk1MzQwYmIyZGU1MjFmYmM1NmM1ZDk3OTljNzMzYjM0YjlkZjFmYmQ0NzUwNzYwODAzOTcxIiwidGFnIjoiIn0%3D; g_content_session=eyJpdiI6Imx2VTQzSHFFTk1xY2tCUUxkSXUxaWc9PSIsInZhbHVlIjoiYmJIZHNQSnU3QWt0Y0Z3K29KUG5SQ1VxOGZTUW1jd056NkE5MEtvYmMxc0pDczZxYmFXZzVxRWIzQjNVcmYyZ2ZTdUx2Uy81QjJUMFJ6WmNyeTAvWHI1SkphZGtJRHhud21WSzJySzU4SGNNSS9iRk55dkpwVG1sVFFselNvcUsiLCJtYWMiOiIwODFkZWMxOTRiYTNmMmJhOWQ1YTFiYzhhODIyZDgwZmQzNDNhZTBjYzYyNDBjOWM5NmQ1YmY3YjAzMzY3Yzk1IiwidGFnIjoiIn0%3D;",
          },
        }
      );

      if (
        response.data.status &&
        response.data.data &&
        response.data.data.url
      ) {
        // If the response is successful, redirect to the provided URL
        window.location.href = response.data.data.url;
      } else {
        setError("Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Payment Error:", error);
      setError(
        "An error occurred during the payment process. Please try again."
      );
    }
  };
  return (
    <>
      <div className="container mx-auto text-black mt-10">
        <div className="sm:flex shadow-md my-10">
          <div className="w-full sm:w-3/4 bg-white px-10 py-10">
            <div className="flex justify-between border-b pb-8">
              <h1 className="font-semibold text-2xl">Shopping Cart</h1>
            </div>
            <div className="md:flex items-stretch py-8 md:py-10 bg-gray-50 p-4 rounded-lg shadow lg:py-8 border-t border-gray-50">
              <div className="md:w-4/12 2xl:w-1/4 w-full">
                <img
                  src={productImage}
                  alt="Product"
                  className="h-full object-center object-cover md:block hidden"
                />
                <img
                  src={productImage}
                  alt="Product"
                  className="md:hidden w-full h-full object-center object-cover"
                />
              </div>
              <div className="flex flex-col md:flex-row py-4">
                <div className="mt-4 md:mt-0 md:ml-6">
                  <h2 className="text-lg font-bold">{title}</h2>
                  <p className="mt-2 text-gray-600">{productDescription}</p>
                  <div className="mt-4 flex items-center">
                    <span className="mr-2 text-gray-600">Quantity:</span>
                    <div className="flex items-center">
                      <button
                        className="bg-gray-200 rounded-l-lg px-2 py-1"
                        onClick={handleDecrement}
                        disabled={quantity === 1}
                      >
                        -
                      </button>
                      <span className="mx-2 text-gray-600">{quantity}</span>
                      <button
                        className="bg-gray-200 rounded-r-lg px-2 py-1"
                        onClick={handleIncrement}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Display Form Data Below */}
            <div className="mt-8 bg-gray-50 p-4 rounded-lg shadow  mx-auto">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">
                Shipping Details
              </h3>
              <div className="space-y-2 text-sm">
                <div className="flex justify-between">
                  <span className="font-semibold">Name:</span>
                  <span>{formData?.name}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold">Email:</span>
                  <span>{formData?.email}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold">Phone Number:</span>
                  <span>{formData?.phone}</span>
                </div>
                <div>
                  <span className="font-semibold">Full Address:</span>
                  <div className="mt-1 text-gray-600 text-sm">
                    <div className="flex justify-between">
                      <span>Address Line 1:</span>
                      <span>{formData?.addressLine1}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Address Line 2:</span>
                      <span>{formData?.addressLine2}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Landmark:</span>
                      <span>{formData?.landmark}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>City:</span>
                      <span>{formData?.city}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>State:</span>
                      <span>{formData?.state}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Pincode:</span>
                      <span>{formData?.pincode}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="summary"
            className="w-full border bg-white sm:w-1/4 md:w-1/2 px-8 py-10"
          >
            <h1 className="font-semibold text-2xl pb-8">Order Summary</h1>
            <div>
              <hr className="my-4" />
              <div className="flex justify-between items-center">
                <span className="font-bold">Price:</span>
                <span className="font-bold">₹{basePrice.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mt-4">
                <span>Quantity:</span>
                <span>x{quantity}</span>
              </div>
              <div className="flex justify-between items-center mt-4">
                <span>Tax & GST:</span>
                <span>₹{taxes}</span>
              </div>
              {applied && (
                <div className="flex justify-between items-center mt-4 text-green-600">
                  <span>
                    Discount ({appliedCoupon} - {discount}%)
                    <button
                      className="ml-2 text-red-500"
                      onClick={handleRemovePromo}
                    >
                      &times;
                    </button>
                  </span>
                  <span>-₹{discountAmount}</span>
                </div>
              )}
              <hr className="my-4" />
              <div className="flex justify-between items-center">
                <span className="font-bold">Total:</span>
                <span className="font-bold">₹{total}</span>
              </div>
            </div>
            {!applied && (
              <>
                <div className="py-10">
                  <label
                    htmlFor="promo"
                    className="font-semibold inline-block mb-3 text-sm uppercase"
                  >
                    Promo Code
                  </label>
                  <input
                    type="text"
                    id="promo"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    placeholder="Enter your code"
                    className="p-2 text-sm w-full"
                  />
                </div>
                <button
                  className="bg-red-500 hover:bg-red-600 px-5 py-2 text-sm text-white uppercase"
                  onClick={handleApplyPromo}
                >
                  Apply
                </button>
              </>
            )}

            <div className="border-t mt-8">
              <button
                onClick={handlePayment}
                className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full"
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryPage;
