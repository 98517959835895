import React from "react";
import QuestionPaperCards from "./QuestionPaperCard";

const QuestionPaperSoftware = () => {
  const plans = [
    {
      title: "MHSB 8th, 9th and 10th",
      Board: "Maharashtra",
      Medium: "English",
      Subjects: ["Languages", "Social Science", "Science", "Maths"],
      "Paper Limit": 500,
      "Login Option": "2 IP address",
      "Classes Logo": "Yes",
      "Water Mark": "Yes",
      Price: "6000",
    },
    {
      title: "MHSB 11th and 12th Commerce",
      Board: "Maharashtra",
      Medium: "English",
      Subjects: ["Economics", "Book Keeping", "SP", "OCM", "Maths"],
      "Paper Limit": 500,
      "Login Option": "2 IP address",
      "Classes Logo": "Yes",
      "Water Mark": "Yes",
      Price: "5000",
    },
    {
      title: "MHSB 11th and 12th Science",
      Board: "Maharashtra",
      Medium: "English",
      Subjects: ["Physics", "Chemistry", "Biology", "Maths"],
      "Paper Limit": 500,
      "Login Option": "2 IP address",
      "Classes Logo": "Yes",
      "Water Mark": "Yes",
      Price: "8000 ",
    },
    {
      title: "CBSE 8th, 9th and 10th (NCERT)",
      Board: "CBSE",
      Medium: "English",
      Subjects: ["English", "Maths", "Science", "Social Science"],
      "Paper Limit": 500,
      "Login Option": "2 IP address",
      "Classes Logo": "Yes",
      "Water Mark": "Yes",
      Price: "6000",
    },
  ];

  return (
    <>
      <div className="w-full">
        <div
          className="rounded-md bg-cover px-[30px] py-[30px] md:px-[64px] md:py-[56px] w-full h-[30vh]"
          style={{
            backgroundImage: `url("https://raw.githubusercontent.com/horizon-ui/horizon-tailwind-react-ts-corporate/main/src/assets/img/nfts/NftBanner1.png")`,
          }}
        >
          <div className="w-full">
            <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
              Generate, customize, and manage question papers effortlessly
            </h4>
            {/* <p className="mb-[40px] max-w-full text-base font-medium text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
              Easily create and organize question papers tailored for different
              subjects and grade levels. Start generating professional-quality
              exam papers with our user-friendly software.
            </p> */}
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-wrap justify-center gap-4 mt-10">
          {plans.map((plan, index) => (
            <QuestionPaperCards key={index} plan={plan} />
          ))}
        </div>
      </div>
    </>
  );
};

export default QuestionPaperSoftware;
