import StudentPlanCard from "./studentPlansCard";

const StudentPlans = () => {
  // Sample JSON data (replace this with your imported or fetched data)
  const productData = [
    {
      course: "MHSB 8th, 9th and 10th",
      board: "Maharashtra",
      medium: "English",
      subjects: ["Languages", "Social Science", "Science", "Maths"],
      paper_limit: 300,
      login_option: "1 student",
      price: 3000,
    },
    {
      course: "MHSB 8th",
      board: "Maharashtra",
      medium: "English",
      subjects: ["Languages", "Social Science", "Science", "Maths"],
      paper_limit: 100,
      login_option: "1 student",
      price: 1000,
    },
    {
      course: "MHSB 8th and 9th",
      board: "Maharashtra",
      medium: "English",
      subjects: ["Languages", "Social Science", "Science", "Maths"],
      paper_limit: 200,
      login_option: "1 student",
      price: 2000,
    },
    {
      course: "MHSB 9th",
      board: "Maharashtra",
      medium: "English",
      subjects: ["Languages", "Social Science", "Science", "Maths"],
      paper_limit: 100,
      login_option: "1 student",
      price: 1000,
    },
    {
      course: "MHSB 9th and 10th",
      board: "Maharashtra",
      medium: "English",
      subjects: ["Languages", "Social Science", "Science", "Maths"],
      paper_limit: 200,
      login_option: "1 student",
      price: 2000,
    },
    {
      course: "MHSB 10th",
      board: "Maharashtra",
      medium: "English",
      subjects: ["Languages", "Social Science", "Science", "Maths"],
      paper_limit: 100,
      login_option: "1 student",
      price: 1000,
    },
    {
      course: "MHSB 11th and 12th Comm",
      board: "Maharashtra",
      medium: "English",
      subjects: ["Maths", "BK", "SP", "OCM"],
      paper_limit: 200,
      login_option: "1 student",
      price: 2500,
    },
    {
      course: "MHSB 11th and 12th Sci",
      board: "Maharashtra",
      medium: "English",
      subjects: ["Maths", "Physics", "Chemistry", "Biology"],
      paper_limit: 200,
      login_option: "1 student",
      price: 3000,
    },
    // Add the rest of the items here
  ];
  return (
    <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {productData.map((product) => (
        <StudentPlanCard key={product.course} product={product} />
      ))}
    </div>
  );
};

export default StudentPlans;
