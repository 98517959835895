import React, { useEffect, useState } from "react";

const ShippingPolicy = () => {
  const [page, setPage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <div className="container bg-white text-black mx-auto my-10 p-5">
        <h1 className="text-3xl font-bold mb-5">Shipping Policy</h1>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">1. Shipping Method</h2>
          <p>
            The orders for the user are shipped through registered domestic
            courier companies and/or speed post only. Orders are shipped within
            7 days from the date of the order and/or payment or as per the
            delivery date agreed at the time of order confirmation and
            delivering of the shipment, subject to courier company / post office
            norms.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">2. Delivery Address</h2>
          <p>
            Delivery of all orders will be made to the address provided by the
            buyer at the time of purchase. Please ensure that the address is
            accurate to avoid any delays or issues with your shipment.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            3. Delivery Confirmation
          </h2>
          <p>
            Delivery of our services will be confirmed to your email ID as
            specified at the time of registration. Ensure that your email
            address is correct and accessible to receive timely notifications.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            4. Liability for Delays
          </h2>
          <p>
            Platform Owner shall not be liable for any delay in delivery by the
            courier company or postal authority. Delays may occur due to various
            reasons beyond our control, including but not limited to courier
            company/post office norms.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">5. Shipping Costs</h2>
          <p>
            If there are any shipping costs levied by the seller or the Platform
            Owner (as applicable), these costs are not refundable under any
            circumstances.
          </p>
        </div>
      </div>
    </>
  );
};

export default ShippingPolicy;
