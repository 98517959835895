import React from "react";
import { useNavigate } from "react-router-dom";

const IDCard = () => {
  const idCardData = {
    ID_Cards: {
      Card_Type: "Chip Card",
      Card_Holder: "Yes",
      Neck_Band: "Yes",
      Customization: "Yes",
      Price: "75",
    },
  };

  const navigate = useNavigate();
  const handlePurchaseClick = () => {
    // Store the image name and price in sessionStorage
    sessionStorage.setItem(
      "productImage",
      "https://i.ibb.co/pbd2S4n/151ee682189021-5f4eb306b08ae.jpg"
    ); // Image file name
    sessionStorage.setItem("productPrice", idCardData.ID_Cards.Price); // Product price
    sessionStorage.setItem("productDescription", idCardData.ID_Cards.Card_Type);
    sessionStorage.setItem("title", idCardData.ID_Cards.Card_Type);

    // Navigate to the checkout page
    navigate("/checkout");
  };

  return (
    <div className="flex   bg-gray-100">
      <div className="bg-white shadow-md rounded-3xl p-4 max-w-lg">
        <div className="flex flex-col lg:flex-row">
          {/* Image Section */}
          <div className="lg:w-48 lg:h-48 w-full h-48 mb-3 lg:mb-0">
            <img
              src="https://i.ibb.co/pbd2S4n/151ee682189021-5f4eb306b08ae.jpg" // Replace with a relevant image URL
              alt="ID Card"
              className="w-full h-full object-cover rounded-2xl"
            />
          </div>

          {/* Content Section */}
          <div className="flex-auto ml-0 lg:ml-6 justify-evenly py-2">
            <div className="text-blue-700 text-sm font-medium">ID Cards</div>
            <h2 className="text-xl font-semibold mt-2 text-gray-800">
              Chip Card ID - {idCardData.ID_Cards.Card_Type}
            </h2>

            <div className="mt-3 space-y-2 text-gray-600">
              <p>Card Holder: {idCardData.ID_Cards.Card_Holder}</p>
              <p>Neck Band: {idCardData.ID_Cards.Neck_Band}</p>
              <p>Customization: {idCardData.ID_Cards.Customization}</p>
            </div>

            <div className="mt-4 text-lg font-semibold text-pink-500">
              ₹ {idCardData.ID_Cards.Price} /- per pcs
            </div>

            <div className="flex space-x-3 mt-6">
              <button
                onClick={handlePurchaseClick}
                className="bg-white border border-gray-300 px-4 py-2 shadow-sm rounded-full text-gray-700 hover:bg-gray-100"
              >
                Order Now
              </button>
              {/* <button className="bg-gray-900 text-white px-4 py-2 shadow-sm rounded-full hover:bg-gray-800">
                View Details
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IDCard;
