import React, { useState } from "react";

import pdfFile from "../../../../assets/documents/EyeRIS_A10.pdf";
import smartPanel from "../../../../assets/smartPanel/EyeRis.png";

import { useNavigate } from "react-router-dom";

// Helper function to render object entries
const renderObject = (obj) => {
  return (
    <ul className="list-disc pl-5">
      {Object.entries(obj).map(([key, value]) => (
        <li key={key} className="py-1">
          <strong>{key}:</strong>{" "}
          {typeof value === "object" && !Array.isArray(value)
            ? renderObject(value) // Recursively render nested objects
            : Array.isArray(value)
            ? value.join(", ") // Join array values as a string
            : value}
        </li>
      ))}
    </ul>
  );
};

const MemorySelection = ({ handlePriceChange }) => {
  const [selectedOption, setSelectedOption] = useState("4GB");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    // Call the callback function to update the price based on selection
    handlePriceChange(option);
  };

  return (
    <div className="flex items-center w-[120px] mb-3">
      <button
        type="button"
        className={`flex items-center w-full px-4 py-2 border text-base font-medium ${
          selectedOption === "4GB"
            ? "text-white bg-indigo-600"
            : "text-black bg-white"
        } border-t border-b border-l rounded-l-md hover:bg-gray-100`}
        onClick={() => handleOptionClick("4GB")}
      >
        4GB
      </button>
      <button
        type="button"
        className={`w-full px-4 py-2 text-base font-medium ${
          selectedOption === "8GB"
            ? "text-white bg-indigo-600"
            : "text-black bg-white"
        } border rounded-r-md hover:bg-gray-100`}
        onClick={() => handleOptionClick("8GB")}
      >
        8GB
      </button>
    </div>
  );
};

// ProductCard component to display product details in a table
const ProductCard = ({ product, disc, price2, price }) => {
  const navigate = useNavigate();
  const [currentPrice, setCurrentPrice] = useState(price);
  const { title, details } = product;
  const handlePdfClick = () => {
    window.open(pdfFile, "_blank");
  };

  const handlePurchaseClick = () => {
    // Store the image name and price in sessionStorage
    sessionStorage.setItem(
      "productImage",
      "https://i.ibb.co/1LHZjxX/Untitled-design-1.png"
    ); // Image file name
    sessionStorage.setItem("productPrice", currentPrice); // Product price
    sessionStorage.setItem("productDescription", disc);
    sessionStorage.setItem("title", title);

    // Navigate to the checkout page
    navigate("/checkout");
  };

  // Default price for 4GB// Default price for 4GB

  const handlePriceChange = (option) => {
    if (option === "4GB") {
      setCurrentPrice(price); // Set price for 4GB
    } else if (option === "8GB") {
      setCurrentPrice(price2); // Set price for 8GB
    }
  };

  return (
    <div className="bg-white  border-t-8 border-green-500  mb-6 rounded-lg shadow-md p-4 md:p-6 overflow-hidden transition-transform transform hover:scale-105">
      {/* Image Section */}
      <div className="mb-4">
        <img
          src={smartPanel}
          alt={title}
          className="w-full h-48 object-cover rounded-t-lg"
        />
      </div>
      <div className="mb-2">
        <div className="text-slate-900  dark:text-slate-200 font-semibold mb-1">
          {title}
        </div>
        <div className="inline-flex items-baseline mb-2">
          <span className="text-slate-900 dark:text-slate-200 font-bold text-xl">
            ₹
          </span>
          <span className="text-slate-900 dark:text-slate-200 font-bold text-xl">
            {currentPrice}
          </span>
        </div>
        <a
          className="w-full cursor-pointer inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
          onClick={handlePurchaseClick}
        >
          Purchase
        </a>
      </div>
      <MemorySelection handlePriceChange={handlePriceChange} />
      <div className="overflow-hidden">
        <table className=" divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-1  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th className="px-1  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Details
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {Object.entries(details).map(([category, detail]) => (
              <tr key={category}>
                <td className="px-1 whitespace-normal text-sm font-medium text-gray-900">
                  {category}
                </td>
                <td className="px-1 whitespace-normal text-sm text-gray-500 break-words">
                  {typeof detail === "object"
                    ? renderObject(detail) // Render nested objects and arrays
                    : detail}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Card Footer with Button */}
        <div className="p-4">
          <button
            onClick={handlePdfClick}
            className="w-full bg-purple-500 text-white rounded-full px-4 py-2 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple active:bg-purple-800"
          >
            More Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
