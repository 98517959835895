import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import logo from "../assets/logo/logo.png";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <>
      {/* Top Bar with Contact Info */}
      <div className="top-bar bg-dark text-white py-1">
        <Container className="d-flex justify-content-start align-items-center">
          <div className="contact-item d-flex align-items-center">
            <i className="fas fa-phone-alt me-2"></i> +91 7710959708
          </div>
          <div className="separator mx-3"></div> {/* Separator line */}
          <div className="contact-item d-flex align-items-center">
            <i className="fas fa-envelope me-2"></i> support@3gcontent.com
          </div>
        </Container>
      </div>

      {/* Navbar Section */}
      <Navbar
        expand="md"
        className={`main-navbar ${scrolled ? "scrolled" : ""}`}
      >
        <Container>
          <Navbar.Brand href="/">
            <img className="max-w-lg" src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                as={Link}
                to="/"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className={
                  activeLink === "about" ? "active navbar-link" : "navbar-link"
                }
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                className={
                  activeLink === "contact"
                    ? "active navbar-link"
                    : "navbar-link"
                }
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/productsPage"
                className={
                  activeLink === "products"
                    ? "active navbar-link"
                    : "navbar-link"
                }
              >
                Products
              </Nav.Link>
              {/* Login Dropdown */}
              <Dropdown className="-mt-2 text-lg">
                <Dropdown.Toggle
                  variant="link"
                  className="navbar-link text-white"
                  id="login-dropdown"
                >
                  Login
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    href="https://app.3gcontent.com/admin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MHSB
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="https://app.examin8.com/login?next=%2Fhome"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CBSE
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
            <span className="navbar-text">
              <HashLink to="#connect">
                <button className="vvd">
                  <span>Let’s Join</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Running Banner Below Navbar */}
      <div className="running-banner bg-primary text-white">
        <div className="banner-content">
          <p>
            Smart Panel on EMI Option Offer valid till Ganpati Festival's for
            more details contact on 9136133510/9136673510
          </p>
        </div>
      </div>
    </>
  );
};
