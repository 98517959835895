import { useEffect, useState } from "react";
import { Banner } from "./Banner";
import { Contact } from "./Contact";
import Contents from "./Content/Contents";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";
import { Projects } from "./Projects";
import ReserchAndPaper from "./ReserchAndPaper/ReserchAndPaper";
import Testimonal from "./Testominal/Testimonal";

const HomePage = () => {
  const [page, setPage] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Contents />
      <ReserchAndPaper />
      <Projects />
      <Testimonal />
      <Contact />
    </div>
  );
};

export default HomePage;
