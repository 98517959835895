import React from "react";
import BrandLedPanel from "./BrandLedPanel";
import QuestionPaperSoftware from "./QuestionPaper/QuestionPaperSoftware";
import IDCard from "./IDCard/IDCard";
import StudentPlans from "./StudentPlans/StudentsPlan";
import Econtent from "./EContent(B2B)/EContent";

function ContentArea({ page }) {
  const renderContent = () => {
    switch (page) {
      case "brands":
        return (
          <div>
            <BrandLedPanel />
          </div>
        );
      case "interactive-board":
        return (
          <div>
            <QuestionPaperSoftware />
          </div>
        );
      case "Bio-Metric":
        return (
          <div>
            <StudentPlans />
          </div>
        );
      case "Students-ID":
        return (
          <div>
            <div className="p-10">
              <IDCard />
            </div>
          </div>
        );
      case "econtent":
        return (
          <div>
            <Econtent />
          </div>
        );
      case "Customize-Answer-Sheet":
        return (
          <div>
            <h1 className="text-2xl font-bold">Customize-Answer-Sheet Page</h1>
            <p className="mt-2 text-gray-600">
              This is the Interactive Customize-Answer-Sheet content.
            </p>
          </div>
        );
      case "Card-Holder":
        return (
          <div>
            <h1 className="text-2xl font-bold">Card-Holder Page</h1>
            <p className="mt-2 text-gray-600">
              This is the Interactive Card-Holder content.
            </p>
          </div>
        );
      case "Laptops":
        return (
          <div>
            <h1 className="text-2xl font-bold">Laptops Page</h1>
            <p className="mt-2 text-gray-600">
              This is the Interactive Laptops content.
            </p>
          </div>
        );
      // Add more cases as necessary
      default:
        return (
          <div>
            <BrandLedPanel />
          </div>
        );
    }
  };

  return <div className="p-4">{renderContent()}</div>;
}

export default ContentArea;
