import React, { useEffect, useState } from "react";

const TermsAndConditions = () => {
  const [page, setPage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <div
      className="container bg-slate-600 rounded mx-auto my-10 p-5"
      style={{ paddingTop: "80px" }}
    >
      <h1 className="text-3xl font-bold mb-5">Terms & Conditions</h1>

      <div className="mb-5">
        <h2 className="text-2xl font-semibold mb-3">1. Introduction</h2>
        <p>
          Welcome to Sarvacharya Education Private Ltd. These Terms and
          Conditions govern your use of our website, services, and products. By
          accessing or using any part of our services, you agree to be bound by
          these terms.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="text-2xl font-semibold mb-3">2. Eligibility</h2>
        <p>
          You must be at least 13 years old to use our services. By using our
          services, you represent and warrant that you meet this age
          requirement.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="text-2xl font-semibold mb-3">3. Use of Services</h2>
        <p>
          You agree to use our services only for lawful purposes and in a way
          that does not infringe the rights of others or restrict or inhibit
          their use and enjoyment of our services. Prohibited behavior includes
          harassing or causing distress or inconvenience to any other user,
          transmitting obscene or offensive content, or disrupting the normal
          flow of dialogue within our services.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="text-2xl font-semibold mb-3">4. Account Registration</h2>
        <p>
          To access certain features of our services, you may be required to
          register an account. You agree to provide accurate and complete
          information when registering and to keep this information up to date.
          You are responsible for safeguarding your account password and for any
          activities or actions under your account.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="text-2xl font-semibold mb-3">
          5. Intellectual Property
        </h2>
        <p>
          All content, trademarks, service marks, logos, and other intellectual
          property displayed on our website are the property of Sarvacharya
          Education Private Ltd or their respective owners. You agree not to use
          any content without prior written permission from us or the respective
          owners.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="text-2xl font-semibold mb-3">
          6. User-Generated Content
        </h2>
        <p>
          Users may submit, upload, or post content to our services. By doing
          so, you grant Sarvacharya Education Private Ltd a non-exclusive,
          worldwide, royalty-free license to use, reproduce, modify, adapt,
          publish, and display such content in connection with the services. You
          represent and warrant that you own or have the necessary rights to all
          content you provide and that such content does not violate the rights
          of any third party.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="text-2xl font-semibold mb-3">7. Privacy</h2>
        <p>
          Our Privacy Policy, which is incorporated into these terms by
          reference, describes how we collect, use, and share your personal
          information. By using our services, you agree to the collection and
          use of information in accordance with our Privacy Policy.
        </p>
      </div>

      <div className="mb-5">
        <h2 className="text-2xl font-semibold mb-3">
          8. Limitation of Liability
        </h2>
        <p>
          To the fullest extent permitted by law, Sarvacharya Education Private
          Ltd shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly, or any loss of
          data, use, goodwill, or other intangible losses, resulting from (i)
          your use or inability to use our services; (ii) any unauthorized
          access to or use of our servers and/or any personal information stored
          therein.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
