import React, { Component } from "react";
import { Skills } from "../Skills";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

const sections = [
  {
    id: 1,
    title: "Content gets updated every academic year:",
    imageUrl: "https://i.ibb.co/0qz9M8k/image1.jpg",
    description: "Content gets updated every academic year.",
  },
  {
    id: 2,
    title: "Faculty team with 10 plus years of experience",
    imageUrl: "https://i.ibb.co/s1Y7c7v/Collaboration.jpg",
    description: "Faculty team with 10 plus years of experience.",
  },
  {
    id: 3,
    title: "Past board papers questions are updated in content",
    imageUrl: "https://i.ibb.co/R2TYpRd/image3.jpg",
    description: "Past board papers questions are updated in content.",
  },
];

export default class Contents extends Component {
  render() {
    return (
      <div className="container">
        <div className="relative overflow-hidden pt-16 pb-32 space-y-24">
          <div className="text-center">
            <h1>Content</h1>
            <h2 className="text-2xl font-bold tracking-tight text-white">
              3G Content aims in delivering updated content, to deliver products
              which are beneficial for students and developing content
              accordingly.
            </h2>
          </div>

          {sections.map((section, index) => (
            <div key={section.id} className="relative">
              <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                <div
                  className={`mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 ${
                    index % 2 === 0 ? "" : "lg:col-start-2"
                  }`}
                >
                  <div>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-pink-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-8 w-8 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                          ></path>
                        </svg>
                      </span>
                    </motion.div>

                    <div className="mt-6">
                      <motion.h2
                        className="text-3xl font-bold tracking-tight text-white"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 0.5 }}
                      >
                        {section.title}
                      </motion.h2>

                      <div className="mt-6">
                        <a
                          className="inline-flex rounded-lg bg-pink-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-pink-600 hover:bg-pink-700 hover:ring-pink-700"
                          href="/login"
                        >
                          Book a Demo Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0">
                  <motion.div
                    className="-ml-48 pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 2, delay: 0.5 }}
                  >
                    <img
                      alt={section.title}
                      loading="lazy"
                      width="647"
                      height="486"
                      className="w-full h-full object-cover rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                      style={{ color: "transparent" }}
                      src={section.imageUrl}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
