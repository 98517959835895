import React from "react";
import { useNavigate } from "react-router-dom";

const QuestionPaperCards = ({ plan }) => {
  const navigate = useNavigate();
  const handlePurchaseClick = () => {
    // Store the image name and price in sessionStorage
    sessionStorage.setItem(
      "productImage",
      "https://i.ibb.co/JCW9xrt/sample-question-papermdi.jpg"
    ); // Image file name
    sessionStorage.setItem("productPrice", plan.Price); // Product price
    sessionStorage.setItem("productDescription", plan.title);
    sessionStorage.setItem("title", plan.title);

    // Navigate to the checkout page
    navigate("/checkout");
  };
  return (
    <div className="max-w-sm mx-auto bg-white rounded-xl shadow-md overflow-hidden">
      <div className="relative mx-4 -mt-6 h-56 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40">
        <img
          src="https://i.ibb.co/G7jPYqf/1681978407php9aneod-1.jpg"
          alt="img-blur-shadow"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6 text-black">
        <h5 className="mb-2 block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
          {plan.title}
        </h5>
        <h2 className="mb-4 block font-sans text-lg font-medium leading-relaxed text-inherit antialiased">
          Price: {plan.Price}
        </h2>
        <div className="flex flex-wrap text-black">
          {/* First Row */}
          <div className="flex w-full space-x-4">
            <div className="w-1/2 p-2 font-light text-sm">
              <span className="font-semibold">Board:</span> {plan.Board}
            </div>
            <div className="w-1/2 p-2 font-light text-sm">
              <span className="font-semibold">Medium:</span> {plan.Medium}
            </div>
          </div>

          {/* Second Row */}
          <div className="flex w-full space-x-4">
            <div className="w-1/2 p-2 font-light text-sm">
              <span className="font-semibold">Paper Limit:</span>{" "}
              {plan["Paper Limit"]}
            </div>
            <div className="w-1/2 p-2 font-light text-sm">
              <span className="font-semibold">Login Option:</span>{" "}
              {plan["Login Option"]}
            </div>
          </div>

          {/* Third Row */}
          <div className="flex w-full space-x-4">
            <div className="w-1/2 p-2 font-light text-sm">
              <span className="font-semibold">Classes Logo:</span>{" "}
              {plan["Classes Logo"]}
            </div>
            <div className="w-1/2 p-2 font-light text-sm">
              <span className="font-semibold">Water Mark:</span>{" "}
              {plan["Water Mark"]}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <button
            onClick={handlePurchaseClick}
            className="select-none cursor-pointer rounded-lg bg-pink-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            data-ripple-light="true"
          >
            Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionPaperCards;
