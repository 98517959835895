import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import ContentArea from "./Pages/ContentArea";
import { useEffect, useState } from "react";
import { NavBarProduct } from "../Common/navbar";

const ProductsPage = () => {
  const [page, setPage] = useState("");

  // const location = useLocation();
  // const project = location.state?.project; // The project data passed from the Projects component

  // if (!project) {
  //   return <div>No project details found</div>;
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      {/* <NavBarProduct /> */}
      <div className="flex h-screen bg-gray-100" style={{ paddingTop: "10px" }}>
        <Sidebar setPage={setPage} />
        <div className="flex flex-col flex-1 overflow-y-auto">
          <ContentArea page={page} />
        </div>
      </div>
    </>
  );
};

export default ProductsPage;
