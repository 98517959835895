import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Projects } from "./components/Projects";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductsPage from "./components/ProductDetails/ProductsPage";
import HomePage from "./components/HomePage";
import { NavBar } from "./components/NavBar";
import { Footer } from "./components/Footer";
import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import BannerContact from "./components/BannerForCOntact/BannerForContact";
import PrivacyPolicy from "./components/Policy/PrivacyPolicy";
import RefundPolicy from "./components/Policy/FundPolicy";
import TermsAndConditions from "./components/Policy/termAndCondition";
import checkout from "./components/Checkout/Checkout";
import Checkout from "./components/Checkout/Checkout";
import SummaryPage from "./components/Checkout/Summary";
import ReturnPolicy from "./components/Policy/ReturnPolicy";
import ShippingPolicy from "./components/Policy/ShippingPolicy";
import SuccessPage from "./components/Payment_message/SuccessPage";
import FailedPayment from "./components/Payment_message/FailedPayment";

function App() {
  return (
    // <Checkout />
    <Router>
      <div className="App">
        <NavBar />
        {/* <BannerContact /> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/productsPage" element={<ProductsPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/fundpolicy" element={<RefundPolicy />} />
          <Route path="/terandcondition" element={<TermsAndConditions />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/summaryPage" element={<SummaryPage />} />
          <Route path="/returnpolicy" element={<ReturnPolicy />} />
          <Route path="/shippingpolicy" element={<ShippingPolicy />} />
          <Route path="/sucesspayment" element={<SuccessPage />} />
          <Route path="/failedpayment" element={<FailedPayment />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
