import { Container } from "react-bootstrap";
import "./AboutUs.css";
import { useEffect, useState } from "react";
const AboutUs = () => {
  const [page, setPage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      <Container style={{ paddingTop: "80px" }}>
        <section class="about-section">
          <div class="container">
            <div class="row">
              <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div class="inner-column">
                  <div class="sec-title">
                    <span class="title">About 3g Content</span>
                    <h2 className="text-white">Our Concept</h2>
                  </div>
                  <div class="text">
                    With nine years of experience in the educational industry,
                    we have collaborated closely with both B2B and B2C sectors,
                    including renowned brands such as Vedantu, Toppr, and
                    Byju's, and have been associated with Vizn. Through this
                    extensive experience, we have gained insight into the unique
                    mindset of students in State Board curriculum compared to
                    CBSE and ICSE boards. While questions in other boards often
                    focus on concepts, State Board examinations predominantly
                    feature textbook-based questions, with non-textual queries
                    being relatively rare. Students typically practice back
                    exercise questions and rely on notes or digests derived from
                    textbooks. Therefore, our products are meticulously crafted
                    based on textbook materials with a focus on textual content.
                  </div>
                  <div class="sec-title">
                    <h2 className="text-white">Our Vision</h2>
                  </div>
                  <div class="text">
                    We aspire to offer the best content at an affordable price
                    point, remaining adaptable to the new changes as per the
                    National Education Policy (NEP).
                  </div>
                  <div class="btn-box">
                    <a href="#" class="theme-btn btn-style-one">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>

              <div class="image-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column wow fadeInLeft">
                  <figure class="image-1">
                    <a href="#" class="lightbox-image" data-fancybox="images">
                      <img
                        title="Rahul Kumar Yadav"
                        src="https://i.ibb.co/f05DLmw/DALL-E-2024-08-18-20-48-51-An-abstract-professional-themed-background-image-suitable-for-an-About-Us.webp"
                        alt=""
                      />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="sec-title">
              <span class="title">Our Future Goal</span>
              <h2 className="text-white">Our Mission</h2>
            </div>
            <div class="text">
              What sets us apart, why choose us, and what matters the most? We
              are dedicated to student success, and to achieve this, we have
              developed content focused on textual exercises and daily practice.
              Our content is aligned with textbook materials and chapter-based
              questions and answers.
            </div>
            <div class="text">
              To enhance practice, we have also created question papers
              following the board pattern. The paramount importance of
              textual-based content in the industry has been addressed
              comprehensively in our offerings, and we are committed to
              continually updating our content to meet evolving needs.
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default AboutUs;
