import React, { useEffect, useState } from "react";

const RefundPolicy = () => {
  const [page, setPage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <div
      className="container bg-slate-600 rounded mx-auto my-10 p-5"
      style={{ paddingTop: "80px" }}
    >
      <div className="mb-5">
        <h1 className="text-3xl font-bold mb-5">Refund Policy</h1>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">1. General</h2>
          <p>
            At Sarvacharya Education Private Ltd, we strive to provide
            high-quality products and services. Please review our policy
            carefully before making a purchase, as all sales are final and we do
            not offer refunds under any circumstances.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            2. Digital Products and Services
          </h2>
          <p>
            All digital products, including but not limited to software,
            subscriptions, e-books, and online courses, are non-refundable once
            the purchase has been made. This policy applies to all users,
            regardless of the circumstances.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            3. Subscription Services
          </h2>
          <p>
            All subscriptions, including monthly, yearly, or any other
            term-based plans, are non-refundable. Once a subscription is
            purchased, the fee is non-refundable, and the subscription will
            remain active for the purchased term. If you choose to cancel your
            subscription, you will continue to have access to the service until
            the end of the current billing cycle.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">4. Exceptions</h2>
          <p>
            We do not offer exceptions to this policy, as it is designed to
            maintain fairness and consistency for all customers. It is the
            customer's responsibility to review the product and its details
            before making a purchase. In rare cases, if a refund is granted at
            our sole discretion, it will be issued in the form of store credit.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">5. Chargebacks</h2>
          <p>
            Initiating a chargeback with your credit card company will be
            considered a violation of this No Refund Policy. We reserve the
            right to dispute any chargebacks and provide proof of purchase and
            the digital delivery of the product or service.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">6. Product Issues</h2>
          <p>
            If you experience any issues with our products or services, please
            contact our customer support team at <strong>9136133510</strong>. We
            are committed to resolving any problems promptly and efficiently.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">
            7. Changes to This Policy
          </h2>
          <p>
            We reserve the right to modify or update this No Refund Policy at
            any time. Any changes will be posted on this page, and it is your
            responsibility to review this policy periodically for any updates.
            Continued use of our services after any changes indicates your
            acceptance of the new policy.
          </p>
        </div>

        <div className="mb-5">
          <h2 className="text-2xl font-semibold mb-3">8. Contact Us</h2>
          <p>
            If you have any questions or concerns about this No Refund Policy,
            please contact us at <strong>[Your Contact Information]</strong>. We
            are here to assist you and ensure a satisfactory experience with our
            products and services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
