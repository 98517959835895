import { useNavigate } from "react-router-dom";

const FailedPayment = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/"); // Redirects to the homepage
  };
  return (
    <>
      <div className="bg-gray-100 h-screen flex items-center justify-center">
        <div className="bg-white p-6 md:w-1/3 w-full shadow-lg rounded-lg">
          <div className="text-center">
            <svg
              className="h-12 w-12 text-red-600 mx-auto mb-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <h3 className="md:text-2xl text-base text-gray-900 font-semibold">
              Your Payment Failed
            </h3>
            <p className="text-gray-600 my-2">Please try again later.</p>

            <div className="py-6 text-center">
              <button
                onClick={handleGoBack}
                className="px-12 bg-red-600 hover:bg-red-700 text-white font-semibold py-3 rounded-full"
              >
                GO BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FailedPayment;
